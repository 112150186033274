import { Trans } from '@common/i18n/trans';
import { Navbar } from '../ui/navigation/navbar/navbar';
import { Link, Outlet, useParams } from 'react-router-dom';
import React, {
    cloneElement,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from 'react';
import { DateRangeValue } from '@common/ui/forms/input-field/date/date-range-picker/date-range-value';
import { DateRangePresets } from '@common/ui/forms/input-field/date/date-range-picker/dialog/date-range-presets';
import { ReportDateSelector } from '@common/admin/analytics/report-date-selector';
import { Button } from '@common/ui/buttons/button';
import { StaticPageTitle } from '@common/seo/static-page-title';
import { LineChart } from '@common/charts/line-chart';
import { FormattedNumber } from '@common/i18n/formatted-number';
import { BaseChartProps } from '@common/charts/base-chart';
import { UseQueryResult } from '@tanstack/react-query';
import {
    FetchInsightsReportResponse,
    InsightsReportMetric,
    useInsightsReport,
} from '@app/admin/reports/requests/use-insights-report';

export interface AdminReportOutletContext {
    dateRange: DateRangeValue;
    setDateRange: (dateRange: DateRangeValue) => void;
}

export interface InsightsReportChartsProps {
    showTracks?: boolean;
    showArtistsAndAlbums?: boolean;
    dateRange?: DateRangeValue;
    model?: string;
}
export function PointPage(props: InsightsReportChartsProps) {
    // will be set via "cloneElement"

    const model = props.model as string;
    const [dateRange, setDateRange] = useState<DateRangeValue>(() => {
        // This week
        return DateRangePresets[2].getRangeValue();
    });

    const title = <Trans message="POINT" />;

    return (
        <div>
            <Navbar
                className="flex-shrink-0 sticky top-0"
                menuPosition="contact-us-page"
            />
            <div className="min-h-full p-12 md:p-24 overflow-x-hidden">
                <div className="md:flex items-center justify-between gap-24 mb-24">
                    <StaticPageTitle>{title}</StaticPageTitle>
                    <div className="flex-shrink-0 flex items-end justify-between gap-40 md:gap-24">
                        <h1 className="mb-24 md:mb-0 text-4xl font-bold">{title}</h1>
                        <div>
                            <Button
                                variant='outline'
                                size="xs"
                                color='primary'
                                elementType={Link}
                                to="/backstage/upload"
                            >
                                <Trans message="Upload" />
                            </Button>
                        </div>
                    </div>
                    <div className="flex-shrink-0 flex items-center justify-between gap-10 md:gap-24">
                        <ReportDateSelector value={dateRange} onChange={setDateRange} />
                    </div>
                </div>
                <div>
                    <div>Total xx point</div>
                    <div>楽曲再生に伴うポイントは、ご自身の登録楽曲の再生数に応じて付与されます。</div>
                </div>
                <Outlet context={{ dateRange, setDateRange }} />
                <div className="flex-shrink-0 flex gap-10 md:gap-24">

                    <AsyncChart metric="points" model={model} dateRange={dateRange}>
                        {({ data }) => (
                            <LineChart
                                className="flex-auto"
                                title={<Trans message="獲得ポイント" />}
                                hideLegend
                                description={
                                    <Trans
                                        message=":count ポイント"
                                        values={{
                                            count: (
                                                <FormattedNumber value={data?.report.points.total || 0} />
                                            ),
                                        }}
                                    />
                                }
                            />
                        )}
                    </AsyncChart>
                </div>
            </div>
        </div>
    );
}

interface AsyncChartProps {
    children:
    | ReactElement<BaseChartProps>
    | ((
        query: UseQueryResult<FetchInsightsReportResponse>
    ) => ReactElement<BaseChartProps>);
    metric: InsightsReportMetric;
    model: string;
    dateRange: DateRangeValue;
}

function AsyncChart({ children, metric, model, dateRange }: AsyncChartProps) {
    const [isEnabled, setIsEnabled] = useState(false);
    const query = useInsightsReport(
        { metrics: [metric], model, dateRange },
        { isEnabled }
    );
    const chart = typeof children === 'function' ? children(query) : children;
    const observerRef = useRef<IntersectionObserver>();

    const contentRef = useCallback((el: HTMLDivElement | null) => {
        if (el) {
            const observer = new IntersectionObserver(
                ([e]) => {
                    if (e.isIntersecting) {
                        setIsEnabled(true);
                        observerRef.current?.disconnect();
                        observerRef.current = undefined;
                    }
                },
                { threshold: 0.1 } // if only header is visible, don't load
            );
            observerRef.current = observer;
            observer.observe(el);
        } else if (observerRef.current) {
            observerRef.current?.disconnect();
        }
    }, []);

    return cloneElement<BaseChartProps>(chart, {
        data: query.data?.report?.[metric],
        isLoading: query.isLoading,
        contentRef,
    });
}

