// points-panel.tsx
import React, { useState, useEffect } from 'react';
import { AccountSettingsPanel } from './account-settings-panel';
import { User } from '../../user';
import { Trans } from '@common/i18n/trans';
import { Button } from '@common/ui/buttons/button';
import { useForm } from 'react-hook-form';
import { Form } from '@common/ui/forms/form';
import { FormTextField } from '@common/ui/forms/input-field/text-field/text-field';
import axios from 'axios';

interface Props {
user: User;
}

interface PointsData {
points: number; // ポイント数を管理するための変数
}

export const PointsPanel = ({ user }: Props) => {
const [currentPoints, setCurrentPoints] = useState<number | null>(null); // 現在のポイント数を保持するステート
const [withdrawalAmount, setWithdrawalAmount] = useState<number | string>(""); // 引き出し金額を保持するステート

useEffect(() => {
    // コンポーネントがマウントされたらAPIからユーザーのポイントを取得
    axios.get('api/v1/user-points')
    .then(response => {
        setCurrentPoints(response.data.points);
    })
    .catch(error => {
        console.error('Error fetching user points:', error);
    });
}, []);

const formId = 'points-form';
const form = useForm<PointsData>({
    defaultValues: {
    points: currentPoints || 0, // 現在のポイント数を表示、nullの場合は0を設定
    },
});

// 引き出し依頼ボタンが押された時の処理
const handleWithdrawal = () => {
    // 1000ポイント未満の場合は引き出し依頼不可
    if (currentPoints && currentPoints < 1000) {
    alert('1000ポイント未満では引き出し依頼ができません。');
    return;
    }

    // 引き出し依頼のロジックをここに書く
    // 例：withdrawPoints(currentPoints, withdrawalAmount);
    console.log('Withdrawal requested for amount:', withdrawalAmount);
};

return (
    <AccountSettingsPanel
    id="user-points"
    title={<Trans message="User Points" />}
    actions={
        <Button
        variant="flat"
        color="primary"
        onClick={handleWithdrawal}
        disabled={currentPoints === null || currentPoints < 1000}
        >
        <Trans message="Withdraw Points" />
        </Button>
    }
    >
    <Form
        form={form}
        id={formId}
        onSubmit={newValues => {
        // ポイント情報の更新ロジックをここに書く
        // 例：updatePoints.mutate(newValues);
        }}
    >
        <FormTextField
        className="mb-24"
        name="current_points"
        label={<Trans message="Current Points" />}
        value={currentPoints !== null ? currentPoints : 0} // 現在のポイント数を表示、nullの場合は0を設定
        type="text"  // もしくは "number" も可能
        required
        disabled  // もし編集不可であれば
        />
        <p>1000ポイント以上で払い出し可能です。</p>
        <FormTextField
        className="mb-24"
        name="withdrawal_amount"
        label={<Trans message="Withdrawal Amount" />}
        value={withdrawalAmount}
        type="number" // 引き出し金額は数値として扱う
        required
        onChange={(e) => setWithdrawalAmount(e.target.value)}
        />
        {/* 他のフィールド */}
    </Form>
    </AccountSettingsPanel>
);
};
